import Layout from "../components/layout"
import Seo from "../components/seo"
import "./mystyles.scss"
import { useEffect, useState } from "react"
import { isLoggedIn } from "../utils/auth.service"
import { graphql, navigate } from "gatsby"
import React from "react"
import authHeader from "../utils/auth-header"
import toast, { Toaster } from "react-hot-toast"
import LoadingSpinner from "../components/loading-spinner"
import { useTranslation } from "gatsby-plugin-react-i18next"

const Settings = () => {
  const { t } = useTranslation()
  const [email, setEmail] = useState("")
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    if (!isLoggedIn()) {
      return navigate("/")
    }

    loadSettings()
  }, [])

  const loadSettings = () => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    }

    setLoading(true)

    fetch(process.env.WASHCAR_API_URL + "/business/settings", requestOptions)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        setLoading(false)
        setEmail(resultData.data.invoice_email);
        
      }) //
  }

  const handleSetEmail = e => {
    e.preventDefault()
    setLoading(true)

    try {
      setSubmitDisabled(true)
      setLoading(true)

      const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
          email: email,
        }),
      }

      fetch(
        process.env.WASHCAR_API_URL +
          "/business/settings/set-custom-invoice-email",
        requestOptions
      )
        .then(response => response.json()) // parse JSON from request
        .then(resultData => {
          setSubmitDisabled(false)
          setLoading(false)

          resultData.status === "Error"
            ? toast.error(resultData.alert.text)
            : toast.success(resultData.alert.text)
        }) // set data for the number of stars
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Layout>
      <Seo title={t("settings")} />
      <h1 className="title">{t("settings")}</h1>

      <Toaster position="top-center" reverseOrder={false} />

      {loading ? (
        <div className="pos-login">
          <LoadingSpinner />
        </div>
      ) : null}

      <div className={"columns"}>
        <form onSubmit={handleSetEmail} className={"box column is-half"}>
          <div className="field">
            <label className="label">{t("email_for_invoices")}</label>
            <div className="control">
              <input
                required
                className="input"
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
          </div>

          <div className="field is-grouped">
            <div className="control">
              <button
                disabled={submitDisabled}
                type="submit"
                className="button is-link"
              >
                {t("save")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  )
}

export default Settings

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
